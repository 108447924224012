import React from "react";

import logo from "../assets/logo.svg";

const Hero = () => (
  <div className="text-center hero my-5">
    <img className="mb-3 app-logo" src={logo} alt="Opzai logo" width="120" />
    <h1 className="mb-4">Welcome to Opzai AI Powered URL Shortener</h1>

    <p className="lead">
    With Opzai, you can seamlessly shorten and track your links while benefiting from intelligent analytics. Streamline your online presence and enhance user experience with Opzai’s cutting-edge technology.
    </p>
  </div>
);

export default Hero;
