import React, { useState } from "react";
import { Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import Loading from "../../components/Loading";
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

const columns: GridColDef[] = [
  {field: 'id', headerName: 'Id', width: 0, hideable: true},
  { field: 'route_id', headerName: 'Route Id', width: 50, hideable: true},
  {field: 'route_token', headerName: 'Shortened Url', width: 50, hideable: true},
  {field: 'statistics_date', headerName: 'Date', width: 500},
  { field: 'total_views', headerName: 'Total Views', width: 500, renderCell: (params) => (
    <span>{params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
  ) }
];

export const Analytics_2Component = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // To get a parameter simply write something like the follwing
  const routeID = urlParams.get('route_id');

  const { apiOrigin = getConfig().originAPI, audience } = getConfig();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    firstRefreshDone: false,
    routeToken: "",
    nothingToShow: true,
    viewByCountry: "/analytics_3?route_id=" + routeID,
    isLoadingStill: true
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callAnalytics(2, routeID);
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callAnalytics(2, routeID);
  };

  const callAnalytics = async (viewType, routeId) => {
    try {
      const token = await getAccessTokenSilently();
      //test
      let targetApi = `${apiOrigin}/analytics_views_1/100/1`;
      if (viewType === 1) {
        targetApi = `${apiOrigin}/analytics_views_1/100/1`;
      }

      if (viewType === 2) {
        targetApi = `${apiOrigin}/analytics_views_2/${routeId}/100/1`;
      }

      if (viewType === 3) {
        targetApi = `${apiOrigin}/analytics_views_3/${routeId}/100/1`;
      }

      const response = await fetch(targetApi, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*'
        },
      });

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.success) {
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
          firstRefreshDone: true,
          routeToken: responseData.routes[0].route_token || '',
          nothingToShow: false,
          isLoadingStill: false
        });
      } else {
        setState({
          ...state,
          showResult: true,
          apiMessage: responseData,
          firstRefreshDone: true,
          nothingToShow: true,
          isLoadingStill: false
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: error.error,
        nothingToShow: true,
        isLoadingStill: false
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  if (!state.firstRefreshDone) {
    callAnalytics(2, routeID);
  }
  // parse the query string's paramters
  if (state.isLoadingStill) {
    return <Loading />;
  }
  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              ERROR_CONSENT Please accept the consent to access
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              ERROR_REQUIRES_LOGIN Please login again
            </a>
          </Alert>
        )}

        {!audience && (
          <Alert color="warning">
            <p>ERROR_AUDIENCE</p>
          </Alert>
        )}
      </div>

      <div className="result-block-container">
        
          <div className="result-block" data-testid="api-result">
            <Box sx={{ height: 400, width: '100%' }}>
            {!state.nothingToShow && (
            <Typography variant="h6" gutterBottom>
              Analytics Daily for {state.routeToken}
              <IconButton aria-label="back" component={Link} to="/analytics" disabled={!audience}>
                <ArrowBackIcon />
              </IconButton>
              <Button variant="outlined" color="primary">
                <Link to={state.viewByCountry}>
                    View by Country
                </Link>
              </Button>
            </Typography>
            )}
            
            {state.nothingToShow && (
                <Typography variant="h6" gutterBottom>
                <IconButton aria-label="back" component={Link} to="/analytics" disabled={!audience}>
                  <ArrowBackIcon />
                </IconButton>
              </Typography>
              )}

            {(state.showResult || !state.nothingToShow) && (
              <DataGrid
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  route_id: false,
                  route_token: false
                }}
                rows={state.apiMessage.routes}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                slots={{
                  toolbar: GridToolbar,
                }}
              />
              )}
            </Box>
          </div>
      </div>
    </>
  );
};

export default withAuthenticationRequired(Analytics_2Component, {
  onRedirecting: () => <Loading />,
});
