import React, { useState } from "react";
import { Alert } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../../config";
import Loading from "../../components/Loading";
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link } from "react-router-dom";

const columns: GridColDef[] = [
  {field: 'id', headerName: 'Id', width: 100},
  { field: 'route_id', headerName: 'Views', width: 100, renderCell: (params) => (
    <Link to={`/analytics_2?route_id=${params.value}`}>{params.value}</Link>
  )  },
  {field: 'friendly_name', headerName: 'Friendly Name', width: 250},
  {field: 'route_token', headerName: 'Shortened Url', width: 250},
  {field: 'route_redirect', headerName: 'Redirection', width: 250},
  {field: 'active', headerName: 'Enabled', width: 100},
  { field: 'total_views', headerName: 'Total Views', width: 200, renderCell: (params) => (
    <span>{params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
  ) }
];

export const Analytics_1Component = () => {
  const { apiOrigin = getConfig().originAPI, audience } = getConfig();

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    firstRefreshDone: false
  });

  const {
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const handleConsent = async () => {
    try {
      await getAccessTokenWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callAnalytics(1, 0);
  };

  const handleLoginAgain = async () => {
    try {
      await loginWithPopup();
      setState({
        ...state,
        error: null,
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }

    await callAnalytics(1, 0);
  };

  const callAnalytics = async (viewType, routeId) => {
    try {
      const token = await getAccessTokenSilently();
      //test
      let targetApi = `${apiOrigin}/analytics_views_1/100/1`;
      if (viewType === 1) {
        targetApi = `${apiOrigin}/analytics_views_1/100/1`;
      }

      if (viewType === 2) {
        targetApi = `${apiOrigin}/analytics_views_2/${routeId}/100/1`;
      }

      if (viewType === 3) {
        targetApi = `${apiOrigin}/analytics_views_3/${routeId}/100/1`;
      }

      const response = await fetch(targetApi, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*'
        },
      });

      const responseData = await response.json();
      console.log(responseData);

      setState({
        ...state,
        showResult: true,
        apiMessage: responseData,
        firstRefreshDone: true
      });
    } catch (error) {
      setState({
        ...state,
        error: error.error
      });
    }
  };

  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };
  if (!state.firstRefreshDone) {
    callAnalytics(1, 0);
  }
  // parse the query string's paramters
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // To get a parameter simply write something like the follwing
  const viewType = urlParams.get('view');
  const routeID = urlParams.get('route_id');
  console.log(viewType + ' ' + routeID);
  let showDaily = false;
  if (viewType) {
    showDaily = viewType.toLowerCase() === 'daily' || true;
  }
  return (
    <>
      <div className="mb-5">
        {state.error === "consent_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleConsent)}
            >
              ERROR_CONSENT Please accept the consent to access
            </a>
          </Alert>
        )}

        {state.error === "login_required" && (
          <Alert color="warning">
            You need to{" "}
            <a
              href="#/"
              class="alert-link"
              onClick={(e) => handle(e, handleLoginAgain)}
            >
              ERROR_REQUIRES_LOGIN Please login again
            </a>
          </Alert>
        )}

        {!audience && (
          <Alert color="warning">
            <p>ERROR_AUDIENCE</p>
          </Alert>
        )}
      </div>

      <div className="result-block-container">
        
          <div className="result-block" data-testid="api-result">
            <Box sx={{ height: 400, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Analytics
              {showDaily && (
                <span>
                &nbsp;Daily
                </span>
              )}
              <IconButton aria-label="refresh" component={Link} to="/analytics" disabled={!audience}>
                <RefreshIcon />
              </IconButton>
            </Typography>
            
            {state.showResult && (
              <DataGrid
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                }}
                rows={state.apiMessage.routes}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                slots={{
                  toolbar: GridToolbar,
                }}
              />
              )}
            </Box>
            {/* <Highlight>
              <span>{JSON.stringify(state.apiMessage.routes, null, 2)}</span>
            </Highlight> */}
          </div>
        
      </div>
    </>
  );
};

export default withAuthenticationRequired(Analytics_1Component, {
  onRedirecting: () => <Loading />,
});
