import React from "react";
import { Container } from "reactstrap";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const ManageUrlComponent = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      Manage Url {user.email}
    </Container>
  );
};

export default withAuthenticationRequired(ManageUrlComponent, {
  onRedirecting: () => <Loading />,
});
