import React, { useState } from "react";
// import { Container } from "reactstrap";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import FormControl, { useFormControlContext } from '@mui/base/FormControl';
import Input, { inputClasses } from '@mui/base/Input';
import { styled } from '@mui/system';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// import { Highlight } from "@mui/icons-material";
// import { create } from "@mui/material/styles/createTransitions";


export const NewUrlComponent = () => {
  const { apiOrigin = getConfig().originAPI } = getConfig();
  const { 
    getAccessTokenSilently,
    } = useAuth0();

  function isUrlValid(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  const validateForm = (state) => {
    const friendly_name_validation = new RegExp("[0-9a-zA-Z- ]{2,50}");
    const friendly_name = state["friendly_name"];
    const shortened_url_validation = new RegExp("[0-9a-zA-Z-]{5,50}");
    const shortened_url = state["shortened_url"];
    // const redirect_validation = new RegExp("^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$");
    const redirect = state["redirect"];

    var isValid = "";
    if (!friendly_name_validation.test(friendly_name)) {
      isValid += "Friendly name is not in the valid format, only [0-9a-zA-Z- ] are allowed and a minimum of 2 characters,";
    }

    if (friendly_name === '') {
      isValid += "Friendly name is empty,";
    }
    
    if (!shortened_url_validation.test(shortened_url)) {
      isValid += "Shortened URL is not in the valid format, only [0-9a-zA-Z-] are allowed and a minimum of 5 characters,";
    }

    if (shortened_url === '') {
      isValid += "Shortened URL is empty,";
    }

    if (!isUrlValid(redirect)) {
      isValid += "Redirect URL is not in the valid format, it needs to be in the format http://... or https://...,";
    }

    if (redirect === '') {
      isValid += "Redirect is empty,";
    }
    if (isValid !== "") {
      return isValid;
    } else {
      return "";
    }
  };

  const handleChange = (e) => {
    setState({...state, [e.target.name]: e.target.value});
  };

  const Submit = (formData) => {
    createNew(formData);
  };

  const createNew = async (state) => {
    const friendly_name = state["friendly_name"];
    const shortened_url = state["shortened_url"];
    const redirect = state["redirect"];
    try {
      const token = await getAccessTokenSilently();
      //test
      let targetApi = `${apiOrigin}/create_new`;
      
      const response = await fetch(targetApi, 
        {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"friendly_name": friendly_name, "shortened_url": shortened_url, "redirect": redirect})
      });

      const responseData = await response.json();
      if (responseData.success) {
        if (responseData.result === 200) {
          alert('Saved successfully.');
        } else {
          alert('An error has occurred #' + responseData.result + ', please try again.');
        }
      }
    } catch (error) {
      alert('An error has occurred, please try again.');
    }
  };

  const [state, setState] = useState({friendly_name: "", shortened_url: "", redirect: ""});

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 2 },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl defaultValue="" required>
      <Label>Friendly Name</Label>
      <StyledInput name="friendly_name" id="friendly_name" placeholder="Write your shortend url friendly name here" onChange={handleChange} />
      </FormControl>
      <FormControl defaultValue="" required>
      <Label>Shortened url</Label>
      <Label id="shortened_url"><StyledInput name="shortened_url" id="shortened_url" placeholder="Write your shortend url here" onChange={handleChange} /></Label>
      </FormControl>
      <FormControl defaultValue="" required>
      <Label>Redirect</Label>
      <StyledInput name="redirect" id="redirect" placeholder="Write your redirection here" onChange={handleChange} />
      </FormControl>
      {validateForm(state)!=="" && (
      <Button
                    id="saveBtn"
                    variant="outlined" 
                    color="primary"
                    className="btn-margin"
                    onClick={() => Submit(state)}
                    disabled
                  >
                    Create
                  </Button>
      )}
      {validateForm(state)==="" && (
      <Button
                    id="saveBtn"
                    variant="outlined" 
                    color="primary"
                    className="btn-margin"
                    onClick={() => Submit(state)}
                  >
                    Create
                  </Button>
      )}
      <div>{validateForm(state)}</div>
      <HelperText />
    </Box>
  );
};

const StyledInput = styled(Input)(
  ({ theme }) => `
  
  .${inputClasses.input} {
    width: 320px;
    font-size: 0.875rem;
    font-family: IBM Plex Sans, sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[300]};
    padding: 8px 12px;
    border-radius: 8px;

    &:hover {
      background: ${theme.palette.mode === 'dark' ? '' : grey[100]};
      border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }

    &:focus {
      outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[100]};
    }
  }
`,
);

const Label = styled(
  ({ children, className }: { children?: React.ReactNode; className?: string }) => {
    const formControlContext = useFormControlContext();
    const [dirty, setDirty] = React.useState(false);

    React.useEffect(() => {
      if (formControlContext?.filled) {
        setDirty(true);
      }
    }, [formControlContext]);

    if (formControlContext === undefined) {
      return <div>{children}</div>;
    }

    const { error, required, filled } = formControlContext;
    const showRequiredError = dirty && required && !filled;

    return (
      <div className={clsx(className, error || showRequiredError ? 'invalid' : '')}>
        {children}
        {required ? ' *' : ''}
      </div>
    );
  },
)`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  margin-bottom: 4px;

  &.invalid {
    color: red;
  }
`;

const HelperText = styled((props: {}) => {
  const formControlContext = useFormControlContext();
  const [dirty, setDirty] = React.useState(false);

  React.useEffect(() => {
    if (formControlContext?.filled) {
      setDirty(true);
    }
  }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { required, filled } = formControlContext;
  const showRequiredError = dirty && required && !filled;

  return showRequiredError ? <div {...props}>This field is required.</div> : null;
})`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
`;

const blue = {
  100: '#DAECFF',
  200: '#80BFFF',
  400: '#3399FF',
  600: '#0072E5',
};

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
};

export default withAuthenticationRequired(NewUrlComponent, {
  onRedirecting: () => <Loading />,
});
