const contentData = [
  {
    title: "Create a Shortened URL",
    link: "/create-short-url",
    description:
      "Creating a shortened URL is a simple and effective way to condense long, complex web addresses into shorter, more manageable links."
  },
  {
    title: "Manage a Shortened URLs",
    link: "/manage-short-url",
    description:
      "Managing shortened URLs is crucial for maintaining a streamlined and organized approach to your online links. Whether you need to update, track, or delete shortened URLs, effective management ensures that your links remain functional, up-to-date, and aligned with your goals."
  },
  {
    title: "View Analytics",
    link: "/analytics",
    description:
      "Analyzing the performance and effectiveness of your shortened URLs is a crucial aspect of optimizing your online marketing efforts. By viewing analytics, you can gain valuable insights into the performance of your links, measure engagement, and make data-driven decisions to enhance your marketing strategies."
  }
];

export default contentData;
